import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoPreview from "components/ui/base/VideoPreview";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface CRMPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: CRMPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.crm.seo.title")}
        description={t("pages.crm.seo.description")}
        image={{
          relativePath: "meta/crm-customer-relationship-management.jpg",
          alt: "Free CRM Software",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.crm.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.crm.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3, 4, 5, 6, 7].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.crm.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.crm.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.crm.title")}
        description={t("pages.crm.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        image={{
          relativePath: "pages/crm/crm-software.jpg",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "CRM",
            path: "/crm/",
          },
        ]}
      />

      <ImageWithDescription
        title={t("pages.crm.1.title")}
        description={t("pages.crm.1.subtitle")}
        image={{
          relativePath: "pages/crm/crm-software-user-data.jpg",
          alt: t("pages.crm.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <VideoPreview
        title={t("pages.crm.2.title")}
        paragraph={t("pages.crm.2.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "pages/crm/crm-contact-timeline.mp4",
          },
        ]}
      />

      <ImageWithDescription
        title={t("pages.crm.3.title")}
        description={t("pages.crm.3.subtitle")}
        image={{
          relativePath: "pages/crm/crm-lead-scoring.jpg",
          alt: t("pages.crm.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.crm.4.title")}
        description={t("pages.crm.4.subtitle")}
        id={"screen-share"}
        image={{
          relativePath: "pages/crm/crm-software-list-creator.jpg",
          alt: t("pages.crm.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.crm.others.title")}
        centered={false}
        sections={[
          {
            title: t("pages.crm.others.helpCenter.title"),
            description: t("pages.crm.others.helpCenter.description"),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.crm.others.helpCenter.cta"),
                path: "/help-center/",
              },
            ],
          },
          {
            title: t("pages.crm.others.surveys.title"),
            description: t("pages.crm.others.surveys.description"),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.crm.others.surveys.cta"),
                path: "/surveys/",
              },
            ],
          },
          {
            title: t("pages.crm.others.funnels.title"),
            description: t("pages.crm.others.funnels.description"),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.crm.others.funnels.cta"),
                path: "/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />

      <QuestionsAndAnswers
        title={t("pages.crm.faq.title")}
        description={t("pages.crm.faq.description")}
        elements={[
          {
            question: t("pages.crm.faq.1.question"),
            answer: t("pages.crm.faq.1.answer"),
          },
          {
            question: t("pages.crm.faq.2.question"),
            answer: t("pages.crm.faq.2.answer"),
          },
          {
            question: t("pages.crm.faq.3.question"),
            answer: t("pages.crm.faq.3.answer"),
          },
          {
            question: t("pages.crm.faq.4.question"),
            answer: t("pages.crm.faq.4.answer"),
          },
          {
            question: t("pages.crm.faq.5.question"),
            answer: t("pages.crm.faq.5.answer"),
          },
          {
            question: t("pages.crm.faq.6.question"),
            answer: t("pages.crm.faq.6.answer"),
          },
          {
            question: t("pages.crm.faq.7.question"),
            answer: t("pages.crm.faq.7.answer"),
          },
        ]}
      />
    </GlobalWrapper>
  );
};

export const crmQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/crm-customer-relationship-management.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
