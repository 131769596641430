import styled from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { StyledAnimation } from "components/core/Animation/base/index.style";
import { media } from "styled-bootstrap-grid";
import { StyledRegisterCTA } from "components/ui/extended/RegisterCTA/index.style";

const StyledRegisterForCustomerService = styled.div`
  color: #fff;
`;

const OuterContainer = styled.div`
  max-width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.gradientAccentColor};
`;

const WrapperContainer = styled.div`
  color: ${(props) => props.theme.baseLightColor};
`;

const HeadingContainer = styled.div`
  gap: 64px;
  display: flex;
  flex-direction: column;
  padding: 26px 0;

  ${media.sm`
    padding: 96px 0;
  `}
  ${StyledHeading} {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;

    ${media.sm`
      font-size: 40px;
  `}
  }

  ${StyledHeading}, p {
    color: #fff;
  }

  ${StyledRegisterCTA} {
    height: 48px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${StyledAnimation} {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const FloatingLeftImage = styled.div`
  position: absolute;
  left: 0;

  ${media.xl`
    bottom: -74px;
    width: 340px;
    left: -2px;
  `}
`;

const FloatingRightImage = styled.div`
  position: absolute;

  ${media.xl`
    bottom: -70px;
    width: 330px;
    left: 195px;
  `}
`;

const FloatingArrowImage = styled.div`
  position: absolute;
  left: 520px;
  top: 325px;
  transform: rotate(335deg);
`;

export {
  StyledRegisterForCustomerService,
  OuterContainer,
  WrapperContainer,
  HeadingContainer,
  ImageContainer,
  FloatingLeftImage,
  FloatingArrowImage,
  FloatingRightImage,
};
